import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full h-full flex flex-col justify-start items-center gap-y-2" }
const _hoisted_2 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_bar_link = _resolveComponent("side-bar-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["z-40 flex-shrink-0 fixed bottom-0 top-14 py-4 pl-2 pr-2 flex flex-col justify-between items-center max-w-full transition-sidebar", [
            'bg-' + _ctx.finalSideBarStyle.sideBarColor,
            'w-60 md:w-18  md:relative md:h-full md:top-0',
            _ctx.isSideBarEnlarged ? 'lg:w-60' : '',
            _ctx.isSideBarOpen ? 'ml-0' : '-ml-60 md:ml-0',
        ]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mainLinks, (link, index) => {
        return (_openBlock(), _createBlock(_component_side_bar_link, {
          key: index,
          link: link,
          isSideBarEnlarged: _ctx.isSideBarEnlarged,
          hasLabel: _ctx.hasLabel,
          sideBarLinkStyle: _ctx.sideBarStyle,
          onClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:isSideBarOpen', false)))
        }, null, 8, ["link", "isSideBarEnlarged", "hasLabel", "sideBarLinkStyle"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.settingsLink)
        ? (_openBlock(), _createBlock(_component_side_bar_link, {
            key: 0,
            link: _ctx.settingsLink,
            isSideBarEnlarged: _ctx.isSideBarEnlarged,
            isSmall: true,
            sideBarLinkStyle: _ctx.sideBarStyle,
            onClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:isSideBarOpen', false)))
          }, null, 8, ["link", "isSideBarEnlarged", "sideBarLinkStyle"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}