import { DropDownStyleInterface } from "./drop_down_interfaces";

export const dropDownDefaultStyle: DropDownStyleInterface = {
    borderColor: "transparent",
    backgroundColor: "white",
    shadow: "shadow",
    widthClasses: "max-w-screen",
    heightClasses: "max-h-80",
    paddingClasses: "p-2",
};
