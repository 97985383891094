import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "bell",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_1763",
      "data-name": "Rechteck 1763",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "bell-2",
      "data-name": "bell",
      d: "M468.5,108.167A9.6,9.6,0,0,1,467.087,103c0-6.677-4.253-8.4-6.121-8.832A.95.95,0,0,0,461,94a1,1,0,0,0-2,0,.89.89,0,0,0,.038.186C457.13,94.654,453,96.42,453,103a9.473,9.473,0,0,1-1.438,5.172c-.38.659-.774,1.34-.364,2.041.49.839,1.261.831,4.96.79.545,0,1.16-.012,1.843-.017V111a2,2,0,0,0,4,0l0-.015c.708,0,1.345.011,1.906.017.686.008,1.27.014,1.771.014,2.191,0,2.775-.121,3.176-.8C469.264,109.515,468.875,108.83,468.5,108.167Zm-4.575.835c-1.039-.011-2.318-.024-3.882-.024s-2.861.013-3.907.024c-1.124.013-2.1.024-2.746,0A11.361,11.361,0,0,0,455,103c0-6.295,4.489-6.926,5.047-6.979.59.051,5.04.651,5.04,6.979a11.508,11.508,0,0,0,1.588,6C466.032,109.025,465.054,109.015,463.925,109Z",
      transform: "translate(-448.025 -91)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}