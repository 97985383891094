import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: _normalizeClass(["rounded-md fixed z-10 border gap-0.5 transition-all", `border-${_ctx.finalDropDownStyle.borderColor} bg-${_ctx.finalDropDownStyle.backgroundColor} ${_ctx.finalDropDownStyle.shadow} ${_ctx.finalDropDownStyle.widthClasses} ${_ctx.finalDropDownStyle.heightClasses} ${_ctx.finalDropDownStyle.paddingClasses}  
    ${_ctx.finalDropDownStyle.widthClasses}`]),
    ref: "dropDownContainerElement"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}