import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative inline-block" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_drop_down_container = _resolveComponent("drop-down-container")!
  const _component_height_transition = _resolveComponent("height-transition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: _ctx.id,
      class: _normalizeClass(_ctx.isDisabled ? '' : 'cursor-pointer'),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (
        _ctx.isDisabled || _ctx.disableTrigger
          ? ''
          : (_ctx.showDropDownVisible = !_ctx.showDropDownVisible)
      ))
    }, [
      _renderSlot(_ctx.$slots, "trigger")
    ], 10, _hoisted_2),
    _createVNode(_component_height_transition, {
      transitionDuration: _ctx.transitionDuration,
      onBeforeEnter: _ctx.positionDropdownContainer
    }, {
      default: _withCtx(() => [
        (_ctx.isDropDownVisible)
          ? _withDirectives((_openBlock(), _createBlock(_component_drop_down_container, {
              key: 0,
              id: _ctx.id + '-container',
              onClose: _ctx.closeDropDown,
              dropdownStyle: _ctx.dropdownStyle
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "content")
              ]),
              _: 3
            }, 8, ["id", "onClose", "dropdownStyle"])), [
              [_vShow, _ctx.showDropDownVisible]
            ])
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["transitionDuration", "onBeforeEnter"])
  ]))
}