import ApiService from "@/common/api_service";
import { getCookie, removeCookie, setCookie } from "@/common/cookies";
import router from "@/router";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { useRoute } from "vue-router";
import { AuthStoreStateInterface } from "../interfaces";
import {
    AuthUserDto,
    LoginRequest,
    TwoFactorRequest,
    ForgotPasswordRequest,
    ResetPasswordRequest,
    RegisterUserByInvitationRequest,
    ChangePasswordInterface,
} from "./interfaces";

export const useAuthStore = defineStore({
    id: "auth",
    state: (): AuthStoreStateInterface => ({
        user: null,
        authCode: { code: "", trust: false },
        authUser: { email: "", password: "", trust: false, token: undefined },
        resetPasswordMail: "",
        registerSuccess: false,
        resettedPassword: false,
        trustBrowser: false,
    }),
    getters: {},
    actions: {
        async login(): Promise<AxiosResponse | null> {
            let routePath = "auth/login";
            let routeName = "NormAdministration";
            let twoFactorRoute = "AuthTwoFactor";
            if (localStorage.getItem("remember_token")) {
                this.authUser.token =
                    localStorage.getItem("remember_token") ?? undefined;
            }
            if (
                router.currentRoute.value.name
                    ?.toString()
                    .includes("CustomerAuth")
            ) {
                routePath = "customers/login";
                routeName = "CustomerNormAdministration";
                twoFactorRoute = "CustomerAuthTwoFactor";
            }
            const response = await ApiService.get({
                resource: "/csrf-cookie",
            }).then(async () => {
                return await ApiService.post({
                    resource: routePath,
                    params: this.authUser,
                });
            });

            if (response?.status == 401) {
                await this.logout();
                this.login();
                return null;
            }
            const authUser = response?.data as AuthUserDto | null;

            if (response?.status === 423) {
                router.push({ name: twoFactorRoute });
                return response;
            }
            if (authUser?.id) {
                setCookie("logged_in", true, 1);
                localStorage.setItem("user_type", authUser.user_type ?? "none");
                this.user = authUser;
                this.authUser = {} as LoginRequest;

                router.push({ name: routeName });
            }
            return response;
        },

        async loginTwoFactor(
            twoFactorRequest: TwoFactorRequest
        ): Promise<AuthUserDto | null> {
            let routePath = "auth/two-factor";
            let routeName = "NormAdministration";
            if (
                router.currentRoute.value.name
                    ?.toString()
                    .includes("CustomerAuth")
            ) {
                routePath = "customers/two-factor";
                routeName = "CustomerNormAdministration";
            }
            const twoFactorParams = twoFactorRequest;
            twoFactorParams.trust = this.trustBrowser;
            const response = await ApiService.post({
                resource: routePath,
                params: twoFactorParams,
            });

            const authUser = response?.data.user as AuthUserDto | null;
            if (authUser?.id) {
                setCookie("logged_in", true, 1);
                localStorage.setItem("user_type", authUser.user_type ?? "none");
                localStorage.setItem(
                    "remember_token",
                    response?.data.token ?? "none"
                );
                this.user = authUser;
                router.push({ name: routeName });
            }
            return authUser;
        },

        resendTwoFactorCode() {
            //TODO
            console.log("resend two factor");
        },

        async forgotPassword(
            forgotPasswordRequest: ForgotPasswordRequest
        ): Promise<AxiosResponse | null> {
            let routePath = "auth/forgot-password";
            let routeName = "AuthForgotPasswordSend";
            if (
                router.currentRoute.value.name
                    ?.toString()
                    .includes("CustomerAuth")
            ) {
                routePath = "customers/forgot-password";
                routeName = "CustomerAuthForgotPasswordSend";
            }
            const response = await ApiService.get({
                resource: "/csrf-cookie",
            }).then(async () => {
                return await ApiService.post({
                    resource: routePath,
                    params: forgotPasswordRequest,
                });
            });
            if (response?.status === 200) {
                router.push({ name: routeName });
            }
            return response;
        },
        resendForgotPassword() {
            // TODO
            console.log("resend forgot password");
        },

        async resetPassword(
            resetPasswordRequest: ResetPasswordRequest
        ): Promise<void> {
            let routePath = "auth/reset-password";
            let routeName = "AuthResetPasswordSuccess";
            if (
                router.currentRoute.value.name
                    ?.toString()
                    .includes("CustomerAuth")
            ) {
                routePath = "customers/reset-password";
                routeName = "CustomerAuthResetPasswordSuccess";
            }
            const response = await ApiService.get({
                resource: "/csrf-cookie",
            }).then(async () => {
                return await ApiService.post({
                    resource: routePath,
                    params: resetPasswordRequest,
                });
            });
            if (response?.status === 200) {
                router.push({ name: routeName });
            }
        },

        async registerUserByInvitation(
            registerUserByInvitationRequest: RegisterUserByInvitationRequest
        ): Promise<boolean> {
            let routePath = "auth/email-invitation";
            let routeName = "AuthRegistrationSuccess";
            if (
                router.currentRoute.value.name
                    ?.toString()
                    .includes("CustomerAuth")
            ) {
                routePath = "customers/email-invitation";
                routeName = "CustomerAuthRegistrationSuccess";
            }
            const response = await ApiService.get({
                resource: "/csrf-cookie",
            }).then(async () => {
                return await ApiService.post({
                    resource: routePath,
                    params: registerUserByInvitationRequest,
                });
            });
            if (response?.status === 200) {
                router.push({ name: routeName });
                return true;
            }
            return false;
        },

        async logout(): Promise<void> {
            let logoutRoute = "/auth/logout";
            let routeName = "AuthLogin";
            const userType = localStorage.getItem("user_type");
            if (userType == "customer") {
                logoutRoute = "/customers/logout";
                routeName = "CustomerAuthLogin";
            }
            await ApiService.post({
                resource: logoutRoute,
            });
            if (getCookie("logged_in")) {
                removeCookie("logged_in");
                localStorage.removeItem("user_type");
            }
            this.user = null;
            router.push({ name: routeName });
        },

        async fetchUser(
            route: string | null = null
        ): Promise<AuthUserDto | null> {
            const cookie = getCookie("logged_in");
            let routePath = "auth/profile";

            const userType = localStorage.getItem("user_type");
            if (route?.includes("CustomerAuth") || userType == "customer") {
                routePath = "customers/profile";
            }
            const response = await ApiService.get({
                resource: routePath,
            });
            const user = response?.data?.data as AuthUserDto | null;
            if (user?.id) {
                this.user = user;
                if (!cookie) {
                    setCookie("logged_in", true, 1);
                    router.push("/");
                }
            }

            return user;
        },

        async updateUser(
            passedUser: AuthUserDto | ChangePasswordInterface
        ): Promise<AxiosResponse | null> {
            let route = "auth/profile";
            if (this.user?.user_type == "customer") {
                route = "customers/profile";
            }
            const response = await ApiService.put({
                resource: route,
                params: passedUser,
            });
            return response;
        },
        async updateUserAvatar(
            passedUser: FormData
        ): Promise<AxiosResponse | null> {
            let route = "auth/profile-image";
            if (this.user?.user_type == "customer") {
                route = "customers/profile-image";
            }
            const response = await ApiService.post({
                resource: route,
                params: passedUser,
            });
            const user = response?.data?.data as AuthUserDto | null;
            if (user?.id) {
                this.user = user;
            }
            this.fetchUser();
            return response;
        },
    },
});
