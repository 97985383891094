
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import IconProject from "./components/icons/IconProject.vue";
import TheSidebar from "./components/layout/theSidebar/TheSidebar.vue";
import { SideBarLinkInterface } from "./components/layout/theSidebar/side_bar_interfaces";
import IconSettings from "./components/icons/IconSettings.vue";
// import NotificationsDropown from "./components/layout/theNavbar/NotificationsDropown.vue";
import AvatarDropdown from "./components/layout/theNavbar/AvatarDropdown.vue";
import TheNavbar from "./components/layout/theNavbar/TheNavbar.vue";
import IconContainer from "./components/helpers/iconContainer/IconContainer.vue";
// import IconSearch from "./components/icons/IconSearch.vue";
import IconMenu2 from "./components/icons/IconMenu2.vue";
import IconUser from "./components/icons/IconUser.vue";
import IconRelation from "./components/icons/IconRelation.vue";
import TheToastMessage from "@/components/helpers/theToastMessage/TheToastMessage.vue";
import { useAuthStore } from "./store/auth";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "./store/settings";
import { useHelpersStore } from "./store/helpers";
import { getCookie, setCookie } from "./common/cookies";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
    name: "App",
    components: {
        TheSidebar,
        // NotificationsDropown,
        AvatarDropdown,
        TheNavbar,
        IconContainer,
        // IconSearch,
        IconMenu2,
        TheToastMessage,
    },
    setup() {
        // const i18n = useI18n();
        const { hasBiggerNavigation } = storeToRefs(useSettingsStore());
        const { toastMessageText } = storeToRefs(useHelpersStore());
        const { user } = storeToRefs(useAuthStore());
        const { fetchUser } = useAuthStore();
        const sideBarLinks: SideBarLinkInterface[] = [
            {
                name: "sidebar.normAdministration",
                routeName: "NormAdministration",
                icon: IconProject,
                required_user_type: "user",
            },
            {
                name: "sidebar.normAdministration",
                routeName: "CustomerNormAdministration",
                icon: IconProject,
                required_user_type: "customer",
            },
            {
                name: "sidebar.customerAdministration",
                routeName: "CustomerAdministration",
                icon: IconUser,
                required_user_type: "user",
            },
            {
                name: "sidebar.customerNormRelation",
                routeName: "CustomerNormRelation",
                icon: IconRelation,
                required_user_type: "user",
            },
        ];
        const settingsLink = {
            name: "sidebar.settings",
            routeName: "Settings",
            icon: IconSettings,
        };
        const isSideBarOpen = ref<boolean>(false);
        const route = useRoute();
        const router = useRouter();

        const filteredSidebarLinks = computed(() => {
            return sideBarLinks.filter(
                (link) => link.required_user_type == user.value?.user_type
            );
        });

        function checkRoute(): boolean {
            const routeName = route.name as string;
            return (
                !route.name ||
                routeName.includes("Auth") ||
                routeName.includes("CustomerAuth")
            );
        }

        watch(
            () => route.name,
            () => {
                const routeName = route.name?.toString();

                if (!user.value && routeName) {
                    fetchUser(routeName);
                }
                if (routeName?.includes("Auth") && getCookie("logged_in")) {
                    router.push({ name: "NormAdministration" });
                }
            },
            { immediate: true }
        );

        if (
            localStorage.getItem("hasBiggerNavigation") &&
            localStorage.getItem("hasBiggerNavigation") == "true"
        ) {
            hasBiggerNavigation.value = true;
        } else {
            hasBiggerNavigation.value = false;
        }

        onMounted(async () => {
            // await fetchUser(route.name?.toString());
        });

        return {
            sideBarLinks,
            settingsLink,
            isSideBarOpen,
            hasBiggerNavigation,
            toastMessageText,
            checkRoute,
            filteredSidebarLinks,
        };
    },
});
