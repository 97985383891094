export interface DropDownStyleInterface {
    borderColor?: string;
    backgroundColor?: string;
    shadow?: string;
    widthClasses?: string;
    heightClasses?: string;
    paddingClasses?: string;
}

export interface DropdownPositionInterface {
    verticalDirection?: VerticalDirectionEnum;
    horizontalDirection?: HorizontalDirectionEnum;
}

export interface DropDownItemStyleInterface {
    backgroundColor?: string;
    backgroundHoverColor?: string;
    backgroundActiveColor?: string;
    paddingClasses?: string;
}

export enum HorizontalDirectionEnum {
    RIGHT = "right",
    LEFT = "left",
}
export enum VerticalDirectionEnum {
    TOP = "top",
    BOTTOM = "bottom",
}
