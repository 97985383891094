
import HeightTransition from "@/components/transitions/HeightTransition.vue";
import { defineComponent, ref, watch } from "vue";
import DropDownContainer from "./DropDownContainer.vue";
import {
  DropdownPositionInterface,
  DropDownStyleInterface,
  HorizontalDirectionEnum,
  VerticalDirectionEnum,
} from "./drop_down_interfaces";
export default defineComponent({
  name: "DropDown",
  components: { DropDownContainer, HeightTransition },
  props: {
    /**
     * id is necessary to uniquely identify this dropdown
     */
    id: {
      type: String,
      required: true,
    },

    /**
     * Duration in Milliseconds for opening transition
     */
    transitionDuration: {
      type: Number,
      default: 150,
    },
    /**
     *adds an overlay if it is disabled
     */ isDisabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Configuration Object for dropdown Style with
     * following attributes:
     * {
     *      ! borderColor?: string;
     *      ! backgroundColor?: string;
     *      ! shadow?: string;
     *      ! widthClasses?: string;
     * }
     */
    dropdownStyle: {
      type: Object as () => DropDownStyleInterface,
      default: {} as DropDownStyleInterface,
    },
    dropdownPosition: {
      type: Object as () => DropdownPositionInterface,
      default: {} as DropdownPositionInterface,
    },
    /**
     * Disables trigger button - can be used to manually open / close the dropdown
     * ClickOutside still works
     */
    disableTrigger: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  setup(props, ctx) {
    const showDropDownVisible = ref<boolean>(false);
    const isDropDownVisible = ref<boolean>(false);

    /**
     * This functionis closing the dropdown.
     *
     * @returns void
     */
    function closeDropDown(): void {
      showDropDownVisible.value = false;
      ctx.emit("close");
    }

    let timeOutFunction: null | number;
    watch(
      () => showDropDownVisible.value,
      () => {
        if (showDropDownVisible.value) {
          isDropDownVisible.value = true;
          if (timeOutFunction) {
            clearTimeout(timeOutFunction);
          }
        } else {
          timeOutFunction = setTimeout(() => {
            closeDropDown();
          }, props.transitionDuration);
        }
      }
    );

    /**************************************************
     * !! Set Fixed Positioning for DropdownContainer
     *************************************************/
    /**
     * Positions dropdown container based on style properties of the container
     */
    function positionDropdownContainer(computedStyle: any): void {
      const trigger = document.getElementById(props.id);
      const container = document.getElementById(props.id + "-container");

      // only position if everything neccessary is not null
      if (trigger == null || computedStyle == null || container == null) return;

      // reset style to avoid dropdown container having old position values
      resetDropdownContainerStyle(container);

      const possibleDirections: Array<string> = calculatePossibleDirections(
        trigger,
        container
      );

      // directions in which the dropdown will open
      const directions: DropdownPositionInterface = decideDirections(
        possibleDirections,
        props.dropdownPosition
      );

      // stretch dropdown to "fullscreen" if no direction is spacious enough
      if (
        directions.horizontalDirection === undefined &&
        container.clientWidth > window.innerWidth
      ) {
        container.style.left = "20px";
        container.style.right = "20px";
      }
      if (directions.verticalDirection === undefined) {
        container.style.top = "100px";
        container.style.right = "20px";
      }
      if (
        directions.horizontalDirection === undefined &&
        directions.verticalDirection === undefined
      )
        return;

      // position dropdown according to positions
      switch (directions.horizontalDirection) {
        case HorizontalDirectionEnum.RIGHT:
          {
            const offsetLeft = trigger.getBoundingClientRect().left;
            container.style.left = offsetLeft + "px";
          }
          break;
        case HorizontalDirectionEnum.LEFT:
          {
            const offsetRight =
              window.innerWidth - trigger.getBoundingClientRect().right;
            container.style.right = offsetRight + "px";
          }
          break;
      }
      switch (directions.verticalDirection) {
        case VerticalDirectionEnum.BOTTOM:
          {
            const offsetTop = trigger.getBoundingClientRect().bottom + 5;
            container.style.top = offsetTop + "px";
          }
          break;
        case VerticalDirectionEnum.TOP:
          {
            const offsetBottom =
              window.innerHeight - trigger.getBoundingClientRect().top + 5;
            container.style.bottom = offsetBottom + "px";
          }
          break;
      }
    }

    const defaultDropdownPosition = {
      verticalDirection: VerticalDirectionEnum.BOTTOM,
      horizontalDirection: HorizontalDirectionEnum.LEFT,
    } as DropdownPositionInterface;

    /**
     * Sets all position attributes (left, right, top, bottom)
     * of a given [container] to empty strings;
     * @param {HTMLElement} container
     */
    function resetDropdownContainerStyle(container: HTMLElement) {
      if (container != null) {
        container.style.left = "unset";
        container.style.right = "unset";
        container.style.top = "unset";
        container.style.bottom = "unset";
      }
    }

    /**
     * Calculates if space between trigger and left, top, right
     * & bottom edge of the view is enough to display the whole dropdown.
     * @param {HTMLElement} trigger - Trigger Element of the dropdown
     * @param {HTMLElement} container - Dropdown Container that needs to positioned & displayed
     * @returns {Array<string>} - Array with possible directions as strings
     */
    function calculatePossibleDirections(
      trigger: HTMLElement,
      container: HTMLElement
    ): Array<VerticalDirectionEnum | HorizontalDirectionEnum> {
      let possibleDirections = [];
      const triggerRects = trigger.getBoundingClientRect();

      if (triggerRects.top > container.clientHeight)
        // top is possible
        possibleDirections.push(VerticalDirectionEnum.TOP);
      if (window.innerHeight - triggerRects.bottom > container.clientHeight)
        // bottom is possible
        possibleDirections.push(VerticalDirectionEnum.BOTTOM);
      if (triggerRects.left > container.clientWidth)
        // left is possible
        possibleDirections.push(HorizontalDirectionEnum.LEFT);
      if (window.innerWidth - triggerRects.right > container.clientWidth)
        // right is possible
        possibleDirections.push(HorizontalDirectionEnum.RIGHT);
      return possibleDirections;
    }

    /**
     * Returns opposite direction string
     * left <-> right
     * top <-> bottom
     * @returns {VerticalDirectionEnum | HorizontalDirectionEnum } direction
     */
    function getOppositeDirection(
      direction: VerticalDirectionEnum | HorizontalDirectionEnum | null
    ): VerticalDirectionEnum | HorizontalDirectionEnum {
      switch (direction) {
        case HorizontalDirectionEnum.LEFT:
          return HorizontalDirectionEnum.RIGHT;
        case HorizontalDirectionEnum.RIGHT:
          return HorizontalDirectionEnum.LEFT;
        case VerticalDirectionEnum.TOP:
          return VerticalDirectionEnum.BOTTOM;
        case VerticalDirectionEnum.BOTTOM:
          return VerticalDirectionEnum.TOP;
      }
      return VerticalDirectionEnum.TOP;
    }

    /**
     * Decides what vertical & horizontal directions to take based on possible and preferred directions
     * Falls back to default positions.
     * If none is available the corresponding direction will be undefined
     * @param {Array<string>} possibleDirections
     * @param {DropdownPositionInterface} preferredDirections
     * @returns {DropdownPositionInterface} decidedDirections
     */
    function decideDirections(
      possibleDirections: Array<string>,
      preferredDirections: DropdownPositionInterface
    ): DropdownPositionInterface {
      let directions = {
        verticalDirection:
          preferredDirections.verticalDirection ??
          defaultDropdownPosition.verticalDirection,
        horizontalDirection:
          preferredDirections.horizontalDirection ??
          defaultDropdownPosition.horizontalDirection,
      } as DropdownPositionInterface;

      // if no vertical direction is possible set undefined
      if (
        !possibleDirections.includes(directions.verticalDirection!) &&
        !possibleDirections.includes(
          getOppositeDirection(directions.verticalDirection!)!
        )
      ) {
        directions.verticalDirection = undefined;
      } else if (
        // if preferred or default direction is not possible, take opposite
        !possibleDirections.includes(directions.verticalDirection!)
      ) {
        directions.verticalDirection = getOppositeDirection(
          directions.verticalDirection!
        ) as VerticalDirectionEnum;
      }
      // if no horizontal direction is possible
      if (
        !possibleDirections.includes(directions.horizontalDirection!) &&
        !possibleDirections.includes(
          getOppositeDirection(directions.horizontalDirection!)!
        )
      ) {
        directions.horizontalDirection = undefined;
      } else if (
        !possibleDirections.includes(directions.horizontalDirection!)
      ) {
        // if preferred or default direction is not possible, take opposite
        directions.horizontalDirection = getOppositeDirection(
          directions.horizontalDirection!
        ) as HorizontalDirectionEnum;
      }
      return directions;
    }

    return {
      showDropDownVisible,
      isDropDownVisible,
      closeDropDown,
      positionDropdownContainer,
    };
  },
});
