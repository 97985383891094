import router from "@/router";
import { useAuthStore } from "@/store/auth";
import { useHelpersStore } from "@/store/helpers";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { storeToRefs } from "pinia";
import { getCookie } from "./cookies";

/**
 * Abstraction layer for api calls
 * Helps to initialize axios interceptors, headers and offers generic functions for get, post, put, patch and delete axios calls
 */
export const ApiService = {
    /**
     * Sets axios default URL and default headers & interceptors
     *
     * @returns void
     */
    init(): void {
        axios.defaults.baseURL = process.env.VUE_APP_API_URL;
        this.setHeader();
        axios.interceptors.response.use(
            (response: AxiosResponse) => response,
            (error: AxiosError) => this.errorHandler(error)
        );
    },
    /**
     * Sets default axios headers
     *
     * @returns void
     */
    setHeader(): void {
        axios.defaults.withCredentials = true;
        axios.defaults.headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "X-CSRFToken": getCookie("csrftoken"),
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        } as any;
    },

    /**
     * Defines how different errors are generally handled
     * Error 401 -> logout user
     * Other error -> add error to store errors and send error to sentry
     *
     * @param  {AxiosError} error
     *
     * @returns void
     */
    errorHandler(error: AxiosError): void {
        const errors = error.response?.data?.errors;

        if (
            error.response?.status == 401 &&
            router.currentRoute.value.name &&
            !router.currentRoute.value.name.toString().includes("Auth") &&
            router.currentRoute.value.meta?.isLoggedIn === true
        ) {
            const { logout } = useAuthStore();
            logout();
            router.push({ name: "Login" });
        } else if (error.response?.status == 422 && errors) {
            const { errorMessages } = storeToRefs(useHelpersStore());
            const { setErrorMessage } = useHelpersStore();
            errorMessages.value = [];
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    const error = errors[key];

                    setErrorMessage(error, key);
                }
            }
        }
        throw error;
    },

    /**
     * @param  {string} .resource
     * @param  {(string| number)?} .slug
     * @param  {{[key:string]: any}?} .params - additional get parameter
     *
     * @returns Promise as AxiosResponse or AxiosError
     */
    async get({
        resource,
        slug,
        params,
    }: {
        resource: string;
        slug?: string | number;
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        params?: { [key: string]: any };
    }): Promise<AxiosResponse | null> {
        const slugString = slug ? `/${slug}` : ``;
        const apiRoute = `${resource}${slugString}`;
        return axios
            .get(apiRoute, { params })
            .then((response: AxiosResponse) => {
                return response;
            })
            .catch((error: AxiosError) => {
                return error.response ?? null;
            });
    },

    /**
     * @param  {string} .resource
     * @param  {{[key:string]:any}} .params
     *
     * @returns Promise as AxiosResponse or AxiosError
     */
    async post({
        resource,
        params,
    }: {
        resource: string;
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        params?: { [key: string]: any };
    }): Promise<AxiosResponse | null> {
        return axios
            .post(`${resource}`, params)
            .then((response: AxiosResponse) => {
                return response;
            })
            .catch((error: AxiosError) => {
                return error.response ?? null;
            });
    },

    /**
     * @param  {string} .resource
     * @param  {(string| number)?} .slug
     * @param  {{[key:string]:any}} .params
     * @param  {AxiosRequestConfig?} .config
     *
     * @returns Promise as AxiosResponse or AxiosError
     */
    async put({
        resource,
        slug,
        params,
        config,
    }: {
        resource: string;
        slug?: string | number;
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        params: { [key: string]: any };
        config?: AxiosRequestConfig;
    }): Promise<AxiosResponse | null> {
        const slugString = slug ? `/${slug}` : ``;
        return axios
            .put(`${resource}${slugString}`, params, config)
            .then((response: AxiosResponse) => {
                return response;
            })
            .catch((error: AxiosError) => {
                return error.response ?? null;
            });
    },

    /**
     * @param  {string} .resource
     * @param  {(string| number)?} .slug
     * @param  {{[key:string]:any}} .params
     * @param  {AxiosRequestConfig?} .config
     *
     * @returns Promise as AxiosResponse or AxiosError
     */
    async patch({
        resource,
        slug,
        params,
        config,
    }: {
        resource: string;
        slug?: string | number;
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        params: { [key: string]: any };
        config?: AxiosRequestConfig;
    }): Promise<AxiosResponse | null> {
        const slugString = slug ? `/${slug}` : ``;
        return axios
            .patch(`${resource}${slugString}`, params, config)
            .then((response: AxiosResponse) => {
                return response;
            })
            .catch((error: AxiosError) => {
                return error.response ?? null;
            });
    },

    /**
     * @param  {string} .resource
     *
     * @returns Promise as AxiosResponse or AxiosError
     */
    async delete({
        resource,
    }: {
        resource: string;
    }): Promise<AxiosResponse | null> {
        return axios
            .delete(`${resource}`)
            .then((response: AxiosResponse) => {
                return response;
            })
            .catch((error: AxiosError) => {
                return error.response ?? null;
            });
    },
};
export default ApiService;
