import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "menu2",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_1755",
      "data-name": "Rechteck 1755",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "outline3",
      d: "M352,93H341a1,1,0,0,0,0,2h11a1,1,0,0,0,0-2Z",
      transform: "translate(-338 -86)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2),
    _createElementVNode("path", {
      id: "outline2",
      d: "M352,101H341a1,1,0,0,0,0,2h11a1,1,0,0,0,0-2Z",
      transform: "translate(-338 -86)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2),
    _createElementVNode("path", {
      id: "outline1",
      d: "M359.707,97.293l-3-3a1,1,0,0,0-1.414,1.414L356.586,97H341a1,1,0,0,0,0,2h15.586l-1.293,1.293a1,1,0,1,0,1.414,1.414l3-3A1,1,0,0,0,359.707,97.293Z",
      transform: "translate(-338 -86)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}