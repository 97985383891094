/**
 * Creates Acronym from any string with 2 or more characters
 * @param {string} acr_string
 * @returns string Acronym: 2 Letters in upper case
 */
export function makeAcronym(acr_string?: string): string {
    let acronym = "";
    if (acr_string != undefined) {
        const acr_array = acr_string.trim().split(" ");

        if (acr_array.length >= 2) {
            acronym =
                acr_array[0].charAt(0) +
                "" +
                acr_array[acr_array.length - 1].charAt(0);
        }
        if (acr_array.length == 1)
            acronym = acr_array[0].charAt(0) + "" + acr_array[0].charAt(1);
    }

    return acronym.toUpperCase();
}
