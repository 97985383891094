
import { defineComponent } from "vue";
/**
 * The content of this component should not have a height or overflow attribute.
 * Only max height is allowed.
 */
export default defineComponent({
    name: "HeightTransition",
    props: {
        /**
         * Duration in Milliseconds for transition
         */
        transitionDuration: {
            type: Number,
            default: 150,
        },
    },
    emits: ["beforeEnter"],
    setup(props, ctx) {
        /**
         * This function is calculating the height of the content and sets
         * the height to 0 and afterwards to auto, to trigger the animation
         *
         * @param  {HTMLElement} element
         * @returns void
         */
        function enter(element: HTMLElement): void {
            element.style.height = "auto";
            const height = getComputedStyle(element).height;
            ctx.emit("beforeEnter", getComputedStyle(element));
            element.style.height = "0";
            element.style.overflow = "hidden";
            getComputedStyle(element).height;
            requestAnimationFrame(() => {
                element.style.height = height;
            });
        }

        /**
         * This function is getting triggert when the height transition is done
         * and enables the overflow.
         *
         * @param  {HTMLElement} element
         * @returns void
         */
        function afterEnter(element: HTMLElement): void {
            element.style.height = "auto";
            element.style.overflow = "auto";
        }

        /**
         * This function enables the close transition.
         *
         * @param  {HTMLElement} element
         * @returns void
         */
        function leave(element: HTMLElement): void {
            const height = getComputedStyle(element).height;
            element.style.height = height;
            getComputedStyle(element).height;
            element.style.overflow = "hidden";
            requestAnimationFrame(() => {
                element.style.height = "0";
            });
        }

        return {
            enter,
            afterEnter,
            leave,
        };
    },
});
