export enum ToastMessageType {
    DEFAULT = "Default",
    WARNING = "Warning",
    ERROR = "Error",
    SUCCESS = "Success",
}

export interface ToastMessageInterface {
    type?: ToastMessageType;
    message: string;
}
