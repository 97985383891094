import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "user",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_1751",
      "data-name": "Rechteck 1751",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "user-2",
      "data-name": "user",
      d: "M262.588,103.2A5.629,5.629,0,0,0,265,98.5a5.023,5.023,0,1,0-10,0,5.629,5.629,0,0,0,2.412,4.7c-3.788.589-6.412,2.457-6.412,4.8,0,2.851,3.869,5,9,5s9-2.149,9-5C269,105.654,266.376,103.786,262.588,103.2ZM260,95a3.542,3.542,0,1,1-3,3.5A3.283,3.283,0,0,1,260,95Zm0,16c-4.006,0-7-1.584-7-3s2.994-3,7-3,7,1.584,7,3S264.006,111,260,111Z",
      transform: "translate(-248 -91)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}