
import { defineComponent } from "vue";

export default defineComponent({
  name: "ModalContentTransition",
  props: {
    /**
     * Duration in Milliseconds for transition
     */
    transitionDuration: {
      type: [String, Number],
      default: 150,
    },
  },
  setup() {
    /**
     * Enter transition
     *
     * @param  {HTMLElement} element
     * @returns void
     */
    function enter(element: HTMLElement): void {
      element.style.bottom = "-100%";
    }

    /**
     * After enter transition
     *
     * @param  {HTMLElement} element
     * @returns void
     */
    function afterEnter(element: HTMLElement): void {
      element.style.bottom = "0";
    }

    /**
     *leave  transition
     *
     * @param  {HTMLElement} element
     * @returns void
     */
    function leave(element: HTMLElement): void {
      requestAnimationFrame(() => {
        element.style.bottom = "-100%";
      });
    }

    return {
      enter,
      afterEnter,
      leave,
    };
  },
});
