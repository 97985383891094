import { createI18n } from "vue-i18n";
import translation from "./translation.json";

const i18n = createI18n({
    messages: translation,
    locale: process.env.VUE_APP_LOCALE ? process.env.VUE_APP_LOCALE : "de",
    fallbackLocale: "de",
    globalInjection: true,
    warnHtmlInMessage: "off", // workaround for not displaying warnings
    legacy: false,
});

export default i18n;
