import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["h-4 w-4 justify-center items-center flex bg-white rounded-full", 'bg-' + _ctx.finalNotificationContainerStyle.borderColor])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["h-2 w-2 rounded-full", 'bg-' + _ctx.finalNotificationContainerStyle.backgroundColor])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute top-0 w-auto justify-end items-end", _ctx.rightSided ? 'left-3.5' : 'right-3.5'])
    }, [
      (_ctx.showCount)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass(["text-xs font-semibold", 'text-' + _ctx.finalNotificationContainerStyle.textColor])
          }, _toDisplayString(_ctx.count), 3))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}