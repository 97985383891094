import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["hover:bg-grey-lightest transition-colors rounded-lg cursor-pointer", 
      'h-' +
      _ctx.finalAvatarContainerStyle.size +
      ' w-' +
      _ctx.finalAvatarContainerStyle.size
    ])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["rounded-full flex justify-center items-center", 
        'h-' +
        _ctx.finalAvatarContainerStyle.size +
        ' w-' +
        _ctx.finalAvatarContainerStyle.size +
        ' bg-' +
        (_ctx.src ? 'transparent' : _ctx.finalAvatarContainerStyle.backgroundColor)
      ])
    }, [
      (_ctx.src && _ctx.src != '')
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "object-cover h-full w-full rounded-full",
            src: _ctx.src,
            alt: ""
          }, null, 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("p", {
            key: 1,
            class: _normalizeClass('text-' + _ctx.finalAvatarContainerStyle.textColor + ' ' + _ctx.textSize)
          }, _toDisplayString(_ctx.acronym), 3))
    ], 2)
  ], 2))
}