import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "settings",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_1769",
      "data-name": "Rechteck 1769",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "outline2",
      d: "M673.956,101.209a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-2a4,4,0,1,0,4,4,4,4,0,0,0-4-4Z",
      transform: "translate(-662.457 -91.209)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2),
    _createElementVNode("path", {
      id: "outline1",
      d: "M675.435,113.209h-2.956a1.5,1.5,0,0,1-1.5-1.5v-1.088a7.953,7.953,0,0,1-1.881-1.1l-.917.537a1.491,1.491,0,0,1-2.052-.553l-1.478-2.592a1.511,1.511,0,0,1,.546-2.045l.927-.543a7.66,7.66,0,0,1,0-2.222l-.927-.542a1.51,1.51,0,0,1-.545-2.046l1.477-2.593a1.495,1.495,0,0,1,2.052-.552l.917.537a7.933,7.933,0,0,1,1.881-1.1V94.712a1.5,1.5,0,0,1,1.5-1.5h2.956a1.5,1.5,0,0,1,1.5,1.5V95.8a7.914,7.914,0,0,1,1.881,1.1l.917-.537a1.493,1.493,0,0,1,2.051.552l1.479,2.594a1.51,1.51,0,0,1-.546,2.045l-.927.542a7.667,7.667,0,0,1,0,2.223l.926.541a1.51,1.51,0,0,1,.547,2.045l-1.478,2.594a1.492,1.492,0,0,1-2.052.552l-.917-.536a7.944,7.944,0,0,1-1.881,1.1v1.088A1.5,1.5,0,0,1,675.435,113.209Zm-2.453-2h1.949v-2.036l.711-.215a5.908,5.908,0,0,0,2.365-1.389l.545-.522,1.744,1.02.984-1.725-1.749-1.023.169-.718a5.881,5.881,0,0,0,0-2.783l-.169-.719,1.749-1.022-.984-1.726-1.744,1.021-.545-.523a5.908,5.908,0,0,0-2.365-1.389l-.711-.214V95.209h-1.949v2.037l-.711.214a5.907,5.907,0,0,0-2.365,1.389l-.545.522-1.744-1.02-.983,1.726,1.748,1.022-.169.719a5.877,5.877,0,0,0,0,2.782l.169.718-1.749,1.023.984,1.726,1.744-1.02.545.522a5.91,5.91,0,0,0,2.365,1.389l.711.215Z",
      transform: "translate(-662.457 -91.209)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}