import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "name", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["flex items-center justify-center rounded-md outline-none transition-all", `bg-${_ctx.finalIconContainerStyle.backgroundColor} hover:bg-${_ctx.finalIconContainerStyle.backgroundHoverColor} focus:bg-${_ctx.finalIconContainerStyle.backgroundFocusColor} w-${_ctx.finalIconContainerStyle.size} h-${_ctx.finalIconContainerStyle.size}`]),
    id: _ctx.id,
    name: _ctx.name,
    title: _ctx.title
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}