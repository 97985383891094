
import { ContainerStyleInterface } from "@/components/helpers/iconContainer/icon_container_style_interface";
import IconContainer from "@/components/helpers/iconContainer/IconContainer.vue";
import IconArrowLeft from "@/components/icons/IconArrowLeft.vue";
import IconArrowRight from "@/components/icons/IconArrowRight.vue";
import { computed, ComputedRef, defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { NavbarStyleInterface } from "./nav_bar_style_interface";
import { navBarDefaultStyle } from "./nav_bar_default_style";
import { getSpaceConfigStyle } from "@/components/space_config_style";
import { cloneData } from "@/common/cloneData";
export default defineComponent({
    components: {
        IconContainer,
        IconArrowLeft,
        IconArrowRight,
    },
    name: "TheNavbar",
    props: {
        /**
         * Title that will displayed if no logo is given
         */
        title: {
            type: String,
            default: process.env.VUE_APP_PROJECT_NAME,
        },
        /**
         * Source for logo
         * e.g. /logo.png
         */
        logoSource: {
            type: String,
            default: null,
        },
        /**
         * Style configurations for navbar
         * default: {
         *      backgroundColor: "white",
         *      shadow: "shadow-light",
         *      paddingClasses: "px6 py-2",
         *      navigationIconColor: "black"
         * }
         */
        navBarStyle: {
            type: Object as () => NavbarStyleInterface,
            default: {} as NavbarStyleInterface,
        },
        /**
         * Style configuration for back and forward buttons
         */
        navigationIconContainerStyle: {
            type: Object as () => ContainerStyleInterface,
            default: {} as ContainerStyleInterface,
        },
        /**
         * Controls whether forward and backward buttons are displayed
         */
        hasNavigationButtons: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const finalNavBarStyle: ComputedRef<NavbarStyleInterface> = computed(
            () => {
                return {
                    ...navBarDefaultStyle,
                    ...getSpaceConfigStyle()?.navBar,
                    ...props.navBarStyle,
                };
            }
        );

        /** Forward & Backwards Buttons **/

        const hasBack = ref(false);
        const hasForward = ref(window.history.state.forward != null);
        const router = useRouter();

        const computedBackIconContainerStyle = computed(() => {
            const navigationIconContainerStyle = cloneData(
                props.navigationIconContainerStyle
            );
            if (!hasBack.value)
                navigationIconContainerStyle.backgroundHoverColor =
                    navigationIconContainerStyle.backgroundColor;

            return navigationIconContainerStyle;
        });
        const computedForwardIconContainerStyle = computed(() => {
            const navigationIconContainerStyle = cloneData(
                props.navigationIconContainerStyle
            );
            if (!hasForward.value)
                navigationIconContainerStyle.backgroundHoverColor =
                    navigationIconContainerStyle.backgroundColor;

            return navigationIconContainerStyle;
        });

        /**
         * Navigates back to last URL
         */
        function back(): void {
            if (hasBack.value) window.history.back();
        }

        /**
         * Navigates forward (only works if back was used before)
         */
        function forward(): void {
            window.history.forward();
        }

        /**
         * Updates localally saved router history.
         * Enables the possibility to react to the state and color the buttons correspondingly
         */
        function updateRouterHistoryState(): void {
            setTimeout(() => {
                if (window.history.state.forward != null) {
                    hasForward.value = true;
                } else {
                    hasForward.value = false;
                }
                if (
                    window.history.state.back != null &&
                    window.history.state.back != "/login" &&
                    window.history.state.back != "/customer-login"
                ) {
                    hasBack.value = true;
                } else {
                    hasBack.value = false;
                }
            }, 100);
        }

        /**
         * Update locally saved router history after every route change
         */
        router.afterEach(() => {
            updateRouterHistoryState();
        });
        return {
            finalNavBarStyle,
            back,
            forward,
            hasBack,
            hasForward,
            computedBackIconContainerStyle,
            computedForwardIconContainerStyle,
        };
    },
});
