
import { computed, ComputedRef, defineComponent } from "vue";
import { AvatarContainerStyleInterface } from "./avatar_container_interfaces";
import { makeAcronym } from "./acronym";
import { avatarContainerDefaultStyle } from "./avatar_container_default_style";
import { getSpaceConfigStyle } from "@/components/space_config_style";
export default defineComponent({
  name: "AvatarContainer",
  components: {},
  props: {
    /**
     * id is necessary to uniquely identify the avatar
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * specifies the name of the HTML avatar element
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * receives an URL to display an image
     */
    src: {
      type: String,
      required: false,
    },
    /**
     * Interface containing size, textColor and backgroundColor to style the avatar
     */
    avatarStyle: {
      type: Object as () => AvatarContainerStyleInterface,
      default: {} as AvatarContainerStyleInterface,
    },
  },
  setup(props) {
    const finalAvatarContainerStyle: ComputedRef<AvatarContainerStyleInterface> =
      computed(() => {
        return {
          ...avatarContainerDefaultStyle,
          ...getSpaceConfigStyle()?.avatarContainer,
          ...props.avatarStyle,
        };
      });

    /**
     * function to shorten the given name
     */
    const acronym = computed(() => {
      return makeAcronym(props.name);
    });
    /**
     * function to determine the needed text size in relation to the avatar size
     */
    const textSize = computed(() => {
      const intSize = parseInt(finalAvatarContainerStyle.value.size!);

      switch (true) {
        case intSize <= 5:
          return "text-xxs";
        case intSize <= 7:
          return "text-xs";
        case intSize <= 10:
          return "text-sm";
        case intSize <= 14:
          return "text-md";
        case intSize <= 24:
          return "text-xl";
        case intSize <= 32:
          return "text-2xl";
        default:
          return "text-3xl";
      }
    });
    return { textSize, finalAvatarContainerStyle, acronym };
  },
});
