import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";
import { defineStore } from "pinia";
import { HelpersStoreStateInterface } from "../interfaces";

export const useHelpersStore = defineStore({
    id: "helper",
    state: (): HelpersStoreStateInterface => ({
        errorMessages: [],
        loadingIds: [],
        toastMessageText: [],
    }),
    getters: {},
    actions: {
        clearStores() {
            console.log("clear store");
        },
        setErrorMessage(error: string[] | string, key: string): void {
            let errorMessage = "";
            if (typeof error === "string") {
                errorMessage = error;
            } else {
                errorMessage = error.join(" ");
            }
            this.errorMessages.push({ name: key, value: errorMessage });
        },
        setToastMessage(type: ToastMessageType, message: string): void {
            this.toastMessageText = [
                {
                    type: type,
                    message: message,
                },
            ];
        },
        removeErrorMessage(name: string): void {
            const errorIndex = this.errorMessages.findIndex(
                (errorObejct) => errorObejct.name == name
            );
            if (errorIndex >= 0) {
                this.errorMessages.splice(errorIndex, 1);
            }
        },
        setLoadingId(id: string): void {
            this.loadingIds.push({ id: id, name: id });
        },
        removeLoadingId(id: string): void {
            const loadingIdIndex = this.loadingIds.findIndex(
                (loadingObject) => loadingObject.id == id
            );
            if (loadingIdIndex >= 0) {
                this.loadingIds.splice(loadingIdIndex, 1);
            }
        },
    },
});
