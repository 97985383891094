
import { computed, ComputedRef, defineComponent } from "vue";
import { NotificationStyleInterface } from "./notification_container_interface";
import { notificationContainerDefaultStyle } from "./notification_container_default_style";
import { getSpaceConfigStyle } from "@/components/space_config_style";

export default defineComponent({
  name: "NotificationContainer",
  components: {},
  props: {
    /**
     * id is necessary to uniquely identify this notification
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * specifies the name of the HTML notification element
     */
    name: {
      type: String,
      required: false,
    },
    /**
     * Number that displays the count of notifications
     */
    count: {
      type: [String, Number],
      required: false,
    },
    /**
     * give the option to show or hide the count
     */
    showCount: {
      type: Boolean,
      default: false,
    },
    /**
     * Interface containing textColor, backgroundColor and borderColor to style the notification
     */
    notificationStyle: {
      type: Object as () => NotificationStyleInterface,
      default: {} as NotificationStyleInterface,
    },
    /**
     * option to show the notification on the right or left side
     */
    rightSided: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const finalNotificationContainerStyle: ComputedRef<NotificationStyleInterface> =
      computed(() => {
        return {
          ...notificationContainerDefaultStyle,
          ...getSpaceConfigStyle()?.notificationContainer,
          ...props.notificationStyle,
        };
      });

    return {
      finalNotificationContainerStyle,
    };
  },
});
