import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    id: _ctx.link.routeName,
    to: { name: _ctx.link.routeName },
    class: _normalizeClass(["rounded-md flex items-center transition-all p-2 w-full h-10 flex-row justify-start pl-4", [
            _ctx.isSideBarEnlarged == false && _ctx.isSmall
                ? 'md:h-10 md:w-10 md:justify-center md:ml-2 md:pl-2'
                : _ctx.isSideBarEnlarged == false
                    ? 'md:h-14 md:flex-col md:pl-2 md:justify-center'
                    : 'md:h-14 lg:h-10 md:flex-col lg:flex-row md:pl-2 lg:pl-4 md:justify-center lg:justify-start',
            _ctx.isLinkCurrentRoute
                ? `bg-${_ctx.finalSideBarLinkStyle.backgroundColor} hover:bg-${_ctx.finalSideBarLinkStyle.activeHoverBackgroundColor} `
                : `bg-transparent hover:bg-${_ctx.finalSideBarLinkStyle.backgroundColor} `,
        ]]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clicked')))
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.link.icon), {
        class: "flex-shrink-0",
        color: 
                _ctx.isLinkCurrentRoute
                    ? `${_ctx.finalSideBarLinkStyle.activeLabelColor}`
                    : `${_ctx.finalSideBarLinkStyle.defaultLabelColor}`
            ,
        fillColor: 
                _ctx.isLinkCurrentRoute
                    ? `${_ctx.finalSideBarLinkStyle.activeLabelColor}`
                    : `${_ctx.finalSideBarLinkStyle.defaultLabelColor}`
            
      }, null, 8, ["color", "fillColor"])),
      (_ctx.hasLabel || _ctx.isSideBarEnlarged)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass(["truncate w-full select-none transition-all ml-4 text-sm text-left", [
                _ctx.isSmall && _ctx.isSideBarEnlarged == false ? 'md:hidden' : '',
                _ctx.isSideBarEnlarged == false
                    ? 'md:mt-0.5 md:ml-0 md:text-xxs md:text-center'
                    : 'md:mt-0.5 lg:mt-0 md:ml-0 lg:ml-4 md:text-xxs lg:text-sm md:text-center lg:text-left',
            
                _ctx.isLinkCurrentRoute
                    ? `text-${_ctx.finalSideBarLinkStyle.activeLabelColor}`
                    : `text-${_ctx.finalSideBarLinkStyle.defaultLabelColor}`,
            ]])
          }, _toDisplayString(_ctx.$t(_ctx.link.name)), 3))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["id", "to", "class"]))
}