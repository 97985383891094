import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "h-full w-full flex flex-col"
}
const _hoisted_2 = { class: "w-full h-full flex flex-row overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_icon_menu_2 = _resolveComponent("icon-menu-2")!
  const _component_icon_container = _resolveComponent("icon-container")!
  const _component_avatar_dropdown = _resolveComponent("avatar-dropdown")!
  const _component_the_navbar = _resolveComponent("the-navbar")!
  const _component_the_sidebar = _resolveComponent("the-sidebar")!
  const _component_the_toast_message = _resolveComponent("the-toast-message")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.checkRoute())
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_the_navbar, {
            logoSource: "/logo.png",
            navigationIconContainerStyle: {
                size: 8,
                backgroundFocusColor: 'transparent',
            }
          }, {
            sidebarToggle: _withCtx(() => [
              _createVNode(_component_icon_container, {
                id: "menu-icon",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isSideBarOpen = !_ctx.isSideBarOpen))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon_menu_2, {
                    color: "black",
                    class: _normalizeClass(["transform transition-transform", _ctx.isSideBarOpen ? ' rotate-180' : ''])
                  }, null, 8, ["class"])
                ]),
                _: 1
              })
            ]),
            right: _withCtx(() => [
              _createVNode(_component_avatar_dropdown)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_the_sidebar, {
              mainLinks: _ctx.filteredSidebarLinks,
              settingsLink: _ctx.settingsLink,
              isSideBarOpen: _ctx.isSideBarOpen,
              isSideBarEnlarged: _ctx.hasBiggerNavigation,
              "onUpdate:isSideBarOpen": _cache[1] || (_cache[1] = (value) => (_ctx.isSideBarOpen = value))
            }, null, 8, ["mainLinks", "settingsLink", "isSideBarOpen", "isSideBarEnlarged"]),
            _createVNode(_component_router_view)
          ])
        ])),
    _createVNode(_component_the_toast_message, {
      modelValue: _ctx.toastMessageText,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.toastMessageText) = $event)),
      hasClearButton: true,
      duration: 3500,
      mainContainerId: 'norm_overview'
    }, null, 8, ["modelValue"])
  ], 64))
}