
import { getSpaceConfigStyle } from "@/components/space_config_style";
import { computed, ComputedRef, defineComponent } from "vue";
import { ContainerStyleInterface } from "./icon_container_style_interface";
import { iconContainerDefaultStyle } from "./icon_container_default_style";

export default defineComponent({
  name: "IconContainer",
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },

    /**
     * Configuration Object for Container Style with
     * following attributes:
     * {
     *      ! backgroundColor?: string;
     *      ! backgroundFocusColor?: string;
     *      ! backgroundHoverColor?: string;
     *      ! size? : number
     * }
     */
    containerStyle: {
      type: Object as () => ContainerStyleInterface,
      default: {} as ContainerStyleInterface,
    },
  },
  setup(props) {
    const finalIconContainerStyle: ComputedRef<ContainerStyleInterface> =
      computed(() => {
        return {
          ...iconContainerDefaultStyle,
          ...getSpaceConfigStyle()?.iconContainer,
          ...props.containerStyle,
        };
      });
    return {
      finalIconContainerStyle,
    };
  },
});
