import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  ref: "toastMessageWrapperElement",
  class: "pointer-events-none fixed z-50"
}
const _hoisted_2 = {
  key: 0,
  class: "absolute pb-8 px-2 w-full flex justify-center pointer-events-auto"
}
const _hoisted_3 = { class: "mr-1 py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notification_container = _resolveComponent("notification-container")!
  const _component_icon_warning = _resolveComponent("icon-warning")!
  const _component_icon_bell = _resolveComponent("icon-bell")!
  const _component_icon_container = _resolveComponent("icon-container")!
  const _component_icon_close = _resolveComponent("icon-close")!
  const _component_modal_content_transition = _resolveComponent("modal-content-transition")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_modal_content_transition, { transitionDuration: _ctx.transitionDuration }, {
        default: _withCtx(() => [
          (_ctx.displayedToastMessage != null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("aside", {
                  class: _normalizeClass(["py-4 px-3 flex gap-2 rounded-md items-center transition-all", `bg-${
                            _ctx.finalToastMessageStyle.backgroundColor
                        } text-${
                            _ctx.displayedToastMessage.type == _ctx.ToastMessageType.ERROR
                                ? 'red'
                                : _ctx.finalToastMessageStyle.textColor
                        } ${_ctx.finalToastMessageStyle.shadow}`])
                }, [
                  _createVNode(_component_icon_container, {
                    id: "toast-message-icon",
                    class: "relative",
                    containerStyle: {
                                backgroundHoverColor:
                                    _ctx.finalToastMessageStyle.backgroundColor,
                                backgroundFocusColor:
                                    _ctx.finalToastMessageStyle.backgroundColor,
                            }
                  }, {
                    default: _withCtx(() => [
                      (
                                    _ctx.modelValue != null && _ctx.modelValue.length > 1
                                )
                        ? (_openBlock(), _createBlock(_component_notification_container, {
                            key: 0,
                            class: "absolute right-1.5 top-1",
                            id: "toast-message-notification-container",
                            count: _ctx.modelValue.length,
                            showCount: true,
                            notificationStyle: {
                                    borderColor:
                                        _ctx.finalToastMessageStyle.backgroundColor,
                                    backgroundColor:
                                        _ctx.finalToastMessageStyle.iconColor,
                                },
                            rightSided: true
                          }, null, 8, ["count", "notificationStyle"]))
                        : _createCommentVNode("", true),
                      (
                                    _ctx.displayedToastMessage.type ==
                                    _ctx.ToastMessageType.ERROR
                                )
                        ? (_openBlock(), _createBlock(_component_icon_warning, {
                            key: 1,
                            color: 'red'
                          }))
                        : (_openBlock(), _createBlock(_component_icon_bell, {
                            key: 2,
                            color: _ctx.finalToastMessageStyle.iconColor
                          }, null, 8, ["color"]))
                    ]),
                    _: 1
                  }, 8, ["containerStyle"]),
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.displayedToastMessage.message), 1),
                  (_ctx.hasClearButton)
                    ? (_openBlock(), _createBlock(_component_icon_container, {
                        key: 0,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearToastMessage())),
                        id: "toast-message-close",
                        containerStyle: { size: 8 }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_icon_close, {
                            class: "w-6 h-6",
                            color: _ctx.finalToastMessageStyle.iconCloseColor
                          }, null, 8, ["color"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ], 2)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["transitionDuration"])
    ], 512)
  ]))
}