import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "warning",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}
const _hoisted_2 = {
  id: "warning-2",
  "data-name": "warning",
  transform: "translate(-1187.707 -90.828)"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_2430",
      "data-name": "Rechteck 2430",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("g", _hoisted_2, [
      _createElementVNode("path", {
        id: "outline3",
        d: "M1199.708,103.829a1,1,0,0,1-1-1v-5a1,1,0,0,1,2,0v5A1,1,0,0,1,1199.708,103.829Z",
        class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
      }, null, 2),
      _createElementVNode("circle", {
        id: "outline2",
        cx: "1.5",
        cy: "1.5",
        r: "1.5",
        transform: "translate(1198.208 105.828)",
        class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
      }, null, 2),
      _createElementVNode("path", {
        id: "outline1",
        d: "M1199.708,94.828a8,8,0,1,1-8,8,8.009,8.009,0,0,1,8-8m0-2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Z",
        class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
      }, null, 2)
    ])
  ]))
}