
import AvatarContainer from "@/components/helpers/avatarContainer/AvatarContainer.vue";
import DropDownItem from "@/components/helpers/dropDown/DropDownItem.vue";
import DropDown from "@/components/helpers/dropDown/DropDown.vue";
import { computed, defineComponent } from "@vue/runtime-core";
import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";
/**
 * This Component is still WIP and needs to be implemented in an own branch
 */
export default defineComponent({
    components: { AvatarContainer, DropDownItem, DropDown },
    name: "AvatarDropdown",
    props: {
        id: {
            type: String,
            default: "avatar-dropdown",
        },
    },
    setup() {
        const { logout } = useAuthStore();
        const { user } = storeToRefs(useAuthStore());

        const displayName = computed(() => {
            let name = "Lauer Normenmanager";
            if (user.value) {
                if (user.value.customer) {
                    name = user.value.customer.name ?? "-";
                } else {
                    name = user.value.full_name ?? "-";
                }
            }
            return name;
        });
        return { logout, user, displayName };
    },
});
