import {
    SideBarLinkStyleInterface,
    SideBarStyleInterface,
} from "./side_bar_interfaces";

export const sideBarDefaultStyle: SideBarStyleInterface = {
    sideBarColor: "black-light",
};
export const sideBarLinkDefaultStyle: SideBarLinkStyleInterface = {
    defaultLabelColor: "grey-light",
    activeLabelColor: "white",
    backgroundColor: "black-lightest",
    activeHoverBackgroundColor: "grey-darkest",
};
