
import { computed, ComputedRef, defineComponent } from "vue";
import { useRoute } from "vue-router";
import {
    SideBarLinkInterface,
    SideBarLinkStyleInterface,
} from "./side_bar_interfaces";
import { sideBarLinkDefaultStyle } from "./side_bar_default_style";
import { getSpaceConfigStyle } from "@/components/space_config_style";
export default defineComponent({
    name: "SideBarLink",
    emits: ['clicked'],
    props: {
        /**
         * Link to the specific route
         * the link have  following attributes:
         * {
         *      ! name: string;
         *      ! routeName: string;
         *      ! icon: Component;
         * }
         */
        link: {
            type: Object as () => SideBarLinkInterface,
            required: true,
        },
        /**
         * changes the size link
         */
        isSideBarEnlarged: {
            type: Boolean,
            default: false,
        },
    /**
     * if it is set only the icon is getting displayed
     */ isSmall: {
            type: Boolean,
            default: false,
        },
        /**
         * removes the link label if it is false
         */
        hasLabel: {
            type: Boolean,
            default: true,
        },
        /**
         * the style of the sidebarlink style
         * this has the following attributes:
         * {
         *      ! defaultLabelColor?: string;
         *      ! activeLabelColor?: string;
         *      ! backgroundColor?: string;
         *      ! activeHoverBackgroundColor?: string;
         * }
         */
        sideBarLinkStyle: {
            type: Object as () => SideBarLinkStyleInterface,
            default: {} as SideBarLinkStyleInterface,
        },
    },
    setup(props) {
        const finalSideBarLinkStyle: ComputedRef<SideBarLinkStyleInterface> =
            computed(() => {
                return {
                    ...sideBarLinkDefaultStyle,
                    ...getSpaceConfigStyle()?.sideBarLink,
                    ...props.sideBarLinkStyle,
                };
            });

        const route = useRoute();
        const isLinkCurrentRoute = computed(() => {
            return route.name && route.name.toString().includes(props.link.routeName);
        });
        return {
            isLinkCurrentRoute,
            finalSideBarLinkStyle,
        };
    },
});
