import { SpaceInterface } from "./space_interface";
let spaceConfigStyle: SpaceInterface | null;
try {
    // TODO export the default style
    spaceConfigStyle = require("@/../space.config.js");
} catch {
    spaceConfigStyle = null;
}

export function getSpaceConfigStyle(): SpaceInterface | null {
    return spaceConfigStyle;
}
