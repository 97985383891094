import { App as AppDto, createApp } from "vue";
import "./assets/style.css";
import "cropperjs/dist/cropper.css";
import "v-calendar/dist/style.css";
import App from "./App.vue";
import router from "./router";
import lang from "./common/lang";
import { createPinia } from "pinia";
import ApiService from "./common/api_service";

ApiService.init();

const app = createApp(App);

// ! ////////////////////////////////////////////////////
// ! This is just necessary for the iconsIndex page,  //
// ! to register the Icons without importing them.   //
// ! /////////////////////////////////////////////////
declare global {
    interface Window {
        vueApp: AppDto<Element>;
    }
}

window.vueApp = app;

app.use(createPinia()).use(router).use(lang).mount("#app");
