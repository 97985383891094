import { defineStore } from "pinia";

export const useSettingsStore = defineStore({
    id: "settings",
    state: () => ({
        selectedLanguage: "Deutsch",
        hasBiggerNavigation: false,
        testLanguages: ["Deutsch", "Englisch", "Französisch"],
        chosenMenuItem: "general",
        chosenSubPage: "",
    }),
    getters: {},
    actions: {},
});
