
import { computed } from "@vue/reactivity";
import { DropDownItemStyleInterface } from "./drop_down_interfaces";
import { defineComponent } from "@vue/runtime-core";
export default defineComponent({
  name: "DropDownItem",
  props: {
    /**
     * ID to identify the dropdown item
     */
    id: {
      type: [String, Number],
      default: null,
    },
    dropdownItemStyle: {
      type: Object as () => DropDownItemStyleInterface,
      default: {} as DropDownItemStyleInterface,
    },
  },
  setup(props) {
    const defaultDropdownItemStyle: DropDownItemStyleInterface = {
      backgroundColor: "white",
      backgroundHoverColor: "white-dark",
      backgroundActiveColor: "white-dark",
      paddingClasses: "px-4 py-2",
    };

    const computedDropDownItemStyle = computed(() => {
      let backgroundStyle = "bg-";
      if (props.dropdownItemStyle.backgroundColor != null) {
        backgroundStyle += props.dropdownItemStyle.backgroundColor;
      } else {
        backgroundStyle += defaultDropdownItemStyle.backgroundColor;
      }
      if (props.dropdownItemStyle.backgroundHoverColor != null) {
        backgroundStyle +=
          " hover:bg-" + props.dropdownItemStyle.backgroundHoverColor;
      } else {
        backgroundStyle +=
          " hover:bg-" + defaultDropdownItemStyle.backgroundHoverColor;
      }
      if (props.dropdownItemStyle.backgroundActiveColor != null) {
        backgroundStyle +=
          " active:bg-" + props.dropdownItemStyle.backgroundActiveColor;
      } else {
        backgroundStyle +=
          " active:bg-" + defaultDropdownItemStyle.backgroundActiveColor;
      }
      if (props.dropdownItemStyle.paddingClasses != null) {
        backgroundStyle += " " + props.dropdownItemStyle.paddingClasses;
      } else {
        backgroundStyle += " " + defaultDropdownItemStyle.paddingClasses;
      }
      return backgroundStyle;
    });
    return {
      defaultDropdownItemStyle,
      computedDropDownItemStyle,
    };
  },
});
