
import { getSpaceConfigStyle } from "@/components/space_config_style";
import { computed, ComputedRef, defineComponent } from "vue";
import {
    SideBarLinkInterface,
    SideBarStyleInterface,
} from "./side_bar_interfaces";
import SideBarLink from "./SideBarLink.vue";
import { sideBarDefaultStyle } from "./side_bar_default_style";
export default defineComponent({
    components: { SideBarLink },
    name: "TheSidebar",
    props: {
        /**
         * Array of links that get displayed on the top
         * the links have  following attributes:
         * {
         *      ! name: string;
         *      ! routeName: string;
         *      ! icon: Component;
         * }
         */
        mainLinks: {
            type: Array as () => Array<SideBarLinkInterface>,
            default: [] as Array<SideBarLinkInterface>,
        },
        /**
         * Link to the settings route
         * the link have  following attributes:
         * {
         *      ! name: string;
         *      ! routeName: string;
         *      ! icon: Component;
         * }
         */
        settingsLink: {
            type: Object as () => SideBarLinkInterface,
            required: false,
        },
        /**
         * changes the size of the sidebar
         */
        isSideBarEnlarged: {
            type: Boolean,
            default: false,
        },
        /**
         * removes the link label if it is false
         */
        hasLabel: {
            type: Boolean,
            default: true,
        },
        /**
         * the style of the sidebar with the sidebarlink style
         * this has the following attributes:
         * {
         *      ! sideBarColor?: string;
         *      ! defaultLabelColor?: string;
         *      ! activeLabelColor?: string;
         *      ! backgroundColor?: string;
         *      ! activeHoverBackgroundColor?: string;
         * }
         */
        sideBarStyle: {
            type: Object as () => SideBarStyleInterface,
            default: {} as SideBarStyleInterface,
        },
        /**
         * Indicates wheter or not the sidebar is opened (enlarged on tabled, visible on mobile)
         */
        isSideBarOpen: {
            type: Boolean,
            default: true,
        },
    },
    emits: ["update:isSideBarOpen"],
    setup(props) {
        const finalSideBarStyle: ComputedRef<SideBarStyleInterface> = computed(
            () => {
                return {
                    ...sideBarDefaultStyle,
                    ...getSpaceConfigStyle()?.sideBar,
                    ...props.sideBarStyle,
                };
            }
        );

        return {
            finalSideBarStyle,
        };
    },
});
