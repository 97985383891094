
import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from "vue";
import { DropDownStyleInterface } from "./drop_down_interfaces";
import { dropDownDefaultStyle } from "./drop_down_default_style";
import { getSpaceConfigStyle } from "@/components/space_config_style";
export default defineComponent({
  name: "DropDownContainer",
  emits: ["close"],
  props: {
    /**
     * id is necessary to uniquely identify this dropdown container
     */
    id: {
      type: String,
    },

    /**
     * Configuration Object for dropdown Style with
     * following attributes:
     * {
     *      ! borderColor?: string;
     *      ! backgroundColor?: string;
     *      ! shadow?: string;
     *      ! widthClasses?: string;
     *      ! heightClasses?: string;
     *      ! paddingClasses?: string;
     * }
     */
    dropdownStyle: {
      type: Object as () => DropDownStyleInterface,
      default: {} as DropDownStyleInterface,
    },
  },
  setup(props, ctx) {
    function closeDropdownContainer() {
      ctx.emit("close");
    }

    const finalDropDownStyle: ComputedRef<DropDownStyleInterface> = computed(
      () => {
        return {
          ...dropDownDefaultStyle,
          ...getSpaceConfigStyle()?.dropDown,
          ...props.dropdownStyle,
        };
      }
    );

    const dropDownContainerElement = ref<HTMLElement>();
    function clickOutside(e: Event): void {
      const dropDownContainer =
        dropDownContainerElement.value as HTMLElement | null;
      if (!dropDownContainer) {
        return;
      }
      const dropDown = dropDownContainer.parentElement;

      if (
        dropDown == null ||
        !e.target ||
        (dropDown != e.target &&
          dropDown.contains(e.target as HTMLElement) == false)
      ) {
        closeDropdownContainer();
        return;
      }
    }

    /**
     * this function closes this container if a parentelement is scrolling
     *
     * @param  {Event} e
     * @returns void
     */
    function handleScrollEvent(e: Event): void {
      const scrollTarget = e.target as HTMLElement;
      if (
        scrollTarget.contains(dropDownContainerElement.value as HTMLElement) &&
        scrollTarget != dropDownContainerElement.value
      ) {
        closeDropdownContainer();
      }
    }

    onMounted(() => {
      document.addEventListener("click", clickOutside);
      document.addEventListener("scroll", handleScrollEvent, true);
      const parent = dropDownContainerElement.value?.parentElement;
      if (
        dropDownContainerElement.value &&
        parent &&
        // " w-" is to only use the width of the parent when width is not set
        !finalDropDownStyle.value?.widthClasses?.startsWith("w-") &&
        !finalDropDownStyle.value?.widthClasses?.includes(" w-")
      ) {
        dropDownContainerElement.value.style.width = `${parent.offsetWidth}px `;
      }
    });

    onUnmounted(() => {
      document.removeEventListener("click", clickOutside);
      document.removeEventListener("scroll", handleScrollEvent, true);
    });

    return {
      finalDropDownStyle,
      dropDownContainerElement,
      closeDropdownContainer,
    };
  },
});
