
import ModalContentTransition from "@/components/transitions/ModalContentTransition.vue";
import {
    computed,
    ComputedRef,
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    watch,
} from "@vue/runtime-core";
import IconBell from "../../icons/IconBell.vue";
import IconWarning from "../../icons/IconWarning.vue";
import IconClose from "../../icons/IconClose.vue";
import IconContainer from "../iconContainer/IconContainer.vue";
import NotificationContainer from "../notificationContainer/NotificationContainer.vue";
import {
    ToastMessageInterface,
    ToastMessageType,
} from "./toast_message_helpers";
import { ToastMessageStyleInterface } from "./toast_message_style_interface";
import { toastMessageDefaultStyle } from "./toast_message_default_style";
import { getSpaceConfigStyle } from "@/components/space_config_style";
export default defineComponent({
    name: "TheToastMessage",
    components: {
        IconBell,
        IconClose,
        IconContainer,
        NotificationContainer,
        ModalContentTransition,
        IconWarning,
    },
    props: {
        /**
         * Array with messages that should be displayed
         */
        modelValue: {
            type: Array as () => Array<ToastMessageInterface>,
            default: [] as Array<ToastMessageInterface>,
        },
        /**
         * Duration in milliseconds for which the ToastMessage will be displayed
         */
        duration: {
            type: [String, Number],
            default: 5000,
        },
        /**
         * Duration in milliseconds for which the ToastMessage will be transitioning
         */
        transitionDuration: {
            type: [String, Number],
            default: 200,
        },
        /**
         * Controls if clear/remove button is visible
         */
        hasClearButton: {
            type: Boolean,
            default: true,
        },
        /**
         * Style Attributes for ToastMessage
         */
        toastMessageStyle: {
            type: Object as () => ToastMessageStyleInterface,
            default: {} as () => ToastMessageStyleInterface,
        },
        /**
         * Main Container id to resize depending on the container
         */
        mainContainerId: {
            type: String,
            default: "main-container-wrapper",
        },
    },
    emits: ["update:modelValue"],
    setup(props, context) {
        /**
         * Currently displayed toast message
         */
        const displayedToastMessage = ref<ToastMessageInterface | null>(null);

        const finalToastMessageStyle: ComputedRef<ToastMessageStyleInterface> =
            computed(() => {
                return {
                    ...toastMessageDefaultStyle,
                    ...getSpaceConfigStyle()?.toastMessage,
                    ...props.toastMessageStyle,
                };
            });

        /**
         * setTimeout instance to be able to preemptiveley cut the timeout function
         */
        const toastMessageTimeout = ref<number | null>();

        /**
         * Sets to be displayed toastmessage and removes it after {props.duration}
         * Also emits "update:modelValue" after removing the displayed ToastMessage
         */
        function displayToastMessage(
            toastMessage: ToastMessageInterface
        ): void {
            // Set current ToastMessage
            displayedToastMessage.value = toastMessage;
            toastMessageTimeout.value = setTimeout(() => {
                // Unset ToastMessage
                displayedToastMessage.value = null;

                // Uses time out before updating modelValue for animation purposes
                setTimeout(() => {
                    // Update & emit updated modelValue
                    let newModelValue = props.modelValue;
                    newModelValue.splice(0, 1);
                    context.emit("update:modelValue", newModelValue);
                }, Number(props.transitionDuration) * 2);
            }, Number(props.duration));
        }

        watch(
            () => props.modelValue,
            (toastMessages: ToastMessageInterface[] | null) => {
                if (
                    displayedToastMessage.value == null &&
                    toastMessages != null &&
                    toastMessages.length > 0
                ) {
                    displayToastMessage(toastMessages[0]);
                }
            },
            { deep: true }
        );

        /**
         * Clears currently running timeOut and removes currently displayed toast message to give way for the next one
         */
        function clearToastMessage(): void {
            if (
                displayedToastMessage.value == null ||
                toastMessageTimeout.value == null
            )
                return;

            clearTimeout(toastMessageTimeout.value);

            if (props.modelValue != null) {
                displayedToastMessage.value = null;
                let newModelValue = props.modelValue;
                newModelValue.splice(0, 1);
                context.emit("update:modelValue", newModelValue);
            }
        }

        const toastMessageWrapperElement = ref<HTMLElement>();

        /**
         * calculates the position of the wrapper Container depending on
         * the size of the main Container
         *
         * @returns void
         */
        function calculateToastMessagePosition(): void {
            const mainContainer = document.getElementById(
                props.mainContainerId
            );

            if (mainContainer) {
                toastMessageWrapperElement.value!.style.width = `${mainContainer.clientWidth}px`;
                toastMessageWrapperElement.value!.style.height = `${mainContainer.clientHeight}px`;
                toastMessageWrapperElement.value!.style.left = `${mainContainer.offsetLeft}px`;
                toastMessageWrapperElement.value!.style.top = `${mainContainer.offsetTop}px`;
            } else {
                toastMessageWrapperElement.value!.style.width = "100%";
                toastMessageWrapperElement.value!.style.height = "100%";
                toastMessageWrapperElement.value!.style.left = "0";
                toastMessageWrapperElement.value!.style.top = "0";
            }
        }

        onMounted(() => {
            calculateToastMessagePosition();
            window.addEventListener(
                "resize",
                calculateToastMessagePosition,
                false
            );
        });
        onUnmounted(() => {
            window.removeEventListener("resize", calculateToastMessagePosition);
        });

        return {
            displayedToastMessage,
            finalToastMessageStyle,
            toastMessageWrapperElement,
            clearToastMessage,
            ToastMessageType,
        };
    },
});
