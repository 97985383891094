import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar_container = _resolveComponent("avatar-container")!
  const _component_drop_down_item = _resolveComponent("drop-down-item")!
  const _component_drop_down = _resolveComponent("drop-down")!

  return (_openBlock(), _createBlock(_component_drop_down, {
    id: _ctx.id,
    dropdownStyle: {
            widthClasses: 'w-32 max-w-screen',
        }
  }, {
    trigger: _withCtx(() => [
      _createVNode(_component_avatar_container, {
        id: "navbar-avatar",
        name: _ctx.displayName,
        src: _ctx.user ? _ctx.user.avatar_url : '',
        avatarStyle: {
                    backgroundColor: 'black',
                    textColor: 'white',
                    size: 10,
                }
      }, null, 8, ["name", "src"])
    ]),
    content: _withCtx(() => [
      _createVNode(_component_drop_down_item, {
        class: "flex",
        onClick: _ctx.logout
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("logout")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["id"]))
}