import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "project",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_1797",
      "data-name": "Rechteck 1797",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "outline3",
      d: "M812.1,105.618h-8a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Z",
      transform: "translate(-795.1 -91.617)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2),
    _createElementVNode("path", {
      id: "outline2",
      d: "M812.1,109.618h-8a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Z",
      transform: "translate(-795.1 -91.617)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2),
    _createElementVNode("path", {
      id: "outline1",
      d: "M815.1,95.617h-8v-.9a1.054,1.054,0,0,0-1-1.1h-4a1.053,1.053,0,0,0-1,1.1v.9h-2a2,2,0,0,0-2,2v14a2,2,0,0,0,2,2h16a2,2,0,0,0,2-2v-14A2,2,0,0,0,815.1,95.617Zm0,16h-16v-14h2v3.455a.493.493,0,0,0,.848.385L804.1,99.1l2.138,2.345a.5.5,0,0,0,.862-.392V97.617h8Z",
      transform: "translate(-795.1 -91.617)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}