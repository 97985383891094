import { getCookie } from "@/common/cookies";
import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/customers",
        component: () => import("../views/customer-administration/Index.vue"),
        meta: {
            isLoggedIn: true,
            required_user_types: ["user"],
        },
        children: [
            {
                path: "/customers",
                name: "CustomerAdministration",
                component: () =>
                    import("../views/customer-administration/Index.vue"),
                meta: {
                    isLoggedIn: true,
                    required_user_types: ["user"],
                },
            },
            {
                path: "/customers/:id",
                name: "CustomerAdministrationDetails",
                component: () =>
                    import("../views/customer-administration/Index.vue"),
                meta: {
                    isLoggedIn: true,
                    required_user_types: ["user"],
                },
            },
        ],
    },

    {
        path: "/",
        component: () => import("../views/normAdministration/Index.vue"),
        meta: {
            isLoggedIn: true,
            required_user_types: ["user"],
        },
        children: [
            {
                path: "",
                name: "NormAdministration",
                component: () =>
                    import("../views/normAdministration/Index.vue"),
                meta: {
                    isLoggedIn: true,
                    required_user_types: ["user"],
                },
            },
            {
                path: "/:id",
                name: "NormAdministrationDetails",
                component: () =>
                    import("../views/normAdministration/Index.vue"),
                meta: {
                    isLoggedIn: true,
                    required_user_types: ["user"],
                },
            },
        ],
    },
    {
        path: "/norms",
        component: () =>
            import("../views/customer-norm-administration/Index.vue"),
        meta: {
            isLoggedIn: true,
            required_user_types: ["customer"],
        },
        children: [
            {
                path: "/norms",
                name: "CustomerNormAdministration",
                component: () =>
                    import("../views/customer-norm-administration/Index.vue"),
                meta: {
                    isLoggedIn: true,
                    required_user_types: ["customer"],
                },
            },
            {
                path: "/norms/:id",
                name: "CustomerNormAdministrationDetails",
                component: () =>
                    import("../views/customer-norm-administration/Index.vue"),
                meta: {
                    isLoggedIn: true,
                    required_user_types: ["customer"],
                },
            },
        ],
    },

    {
        path: "/relation",
        component: () => import("../views/customer-norm-relation/Index.vue"),
        meta: {
            isLoggedIn: true,
            required_user_types: ["user"],
        },
        children: [
            {
                path: "/relation",
                name: "CustomerNormRelation",
                component: () =>
                    import("../views/customer-norm-relation/Index.vue"),
                meta: {
                    isLoggedIn: true,
                    required_user_types: ["user"],
                },
            },
            {
                path: "/relation/:id",
                name: "CustomerNormRelationDetails",
                component: () =>
                    import("../views/customer-norm-relation/Index.vue"),
                meta: {
                    isLoggedIn: true,
                    required_user_types: ["user"],
                },
            },
        ],
    },
    {
        path: "/settings",
        component: () => import("../views/settings/Index.vue"),
        meta: {
            isLoggedIn: true,
        },
        children: [
            {
                path: "",
                name: "Settings",
                component: () =>
                    import("../views/settings/views/general/General.vue"),
                meta: {
                    isLoggedIn: true,
                },
            },
            {
                path: "base-data",
                name: "SettingsBaseData",
                component: () =>
                    import("../views/settings/views/baseData/BaseData.vue"),
                meta: {
                    isLoggedIn: true,
                },
                children: [
                    {
                        path: "states",
                        name: "SettingsBaseDataStates",
                        component: () =>
                            import(
                                "../views/settings/views/baseData/BaseData.vue"
                            ),
                        meta: {
                            isLoggedIn: true,
                            required_user_types: ["customer", "user"],
                        },
                    },
                    {
                        path: "types",
                        name: "SettingsBaseDataTypes",
                        component: () =>
                            import(
                                "../views/settings/views/baseData/BaseData.vue"
                            ),
                        meta: {
                            isLoggedIn: true,
                            required_user_types: ["customer", "user"],
                        },
                    },
                ],
            },
            {
                path: "email",
                name: "SettingsEmail",
                component: () =>
                    import("../views/settings/views/email/Email.vue"),
                meta: {
                    isLoggedIn: true,
                },
                children: [
                    {
                        path: "types",
                        name: "SettingsEmailTypes",
                        component: () =>
                            import(
                                "../views/settings/views/baseData/BaseData.vue"
                            ),
                        meta: {
                            isLoggedIn: true,
                            required_user_types: ["customer", "user"],
                        },
                    },
                ],
            },
            {
                path: "profile",
                name: "SettingsProfile",
                component: () =>
                    import(
                        "../views/settings/views/profil/ProfileAndSecurity.vue"
                    ),
                meta: {
                    isLoggedIn: true,
                },
            },
            {
                path: "user-administration",
                name: "SettingsUserAdmin",
                component: () =>
                    import(
                        "../views/settings/views/userAdministration/UserAdministration.vue"
                    ),
                meta: {
                    isLoggedIn: true,
                },
            },
            {
                path: "role-administration",
                name: "SettingsRoleAdmin",
                component: () =>
                    import(
                        "../views/settings/views/roleAdministration/RoleAdministration.vue"
                    ),
                meta: {
                    isLoggedIn: true,
                },
            },
        ],
    },
    {
        path: "/login",
        name: "AuthLogin",
        component: () => import("../views/auth/Login.vue"),
        meta: {
            isLoggedIn: false,
        },
    },

    {
        path: "/two-factor-authentification",
        name: "AuthTwoFactor",
        component: () => import("../views/auth/TwoFactor.vue"),
        meta: {
            isLoggedIn: false,
        },
    },
    {
        path: "/forgot-password",
        name: "AuthForgotPassword",
        component: () => import("../views/auth/ForgotPassword.vue"),
        meta: {
            isLoggedIn: false,
        },
    },

    {
        path: "/forgot-password-send",
        name: "AuthForgotPasswordSend",
        component: () => import("../views/auth/PasswordSend.vue"),
        meta: {
            isLoggedIn: false,
        },
    },
    {
        path: "/reset-password",
        name: "AuthResetPassword",
        component: () => import("../views/auth/ResetPassword.vue"),
        meta: {
            isLoggedIn: false,
        },
    },
    {
        path: "/reset-password-success",
        name: "AuthResetPasswordSuccess",
        component: () => import("../views/auth/ResetPasswordSuccess.vue"),
        meta: {
            isLoggedIn: false,
        },
    },
    {
        path: "/register",
        name: "AuthRegistration",
        component: () => import("../views/auth/Registration.vue"),
        meta: {
            isLoggedIn: false,
        },
    },
    {
        path: "/registration-success",
        name: "AuthRegistrationSuccess",
        component: () => import("../views/auth/RegisterSuccess.vue"),
        meta: {
            isLoggedIn: false,
        },
    },

    // Customer Auth

    {
        path: "/customer-login",
        name: "CustomerAuthLogin",
        component: () => import("../views/auth/Login.vue"),
        meta: {
            isLoggedIn: false,
        },
    },

    {
        path: "/customer-two-factor-authentification",
        name: "CustomerAuthTwoFactor",
        component: () => import("../views/auth/TwoFactor.vue"),
        meta: {
            isLoggedIn: false,
        },
    },
    {
        path: "/customer-forgot-password",
        name: "CustomerAuthForgotPassword",
        component: () => import("../views/auth/ForgotPassword.vue"),
        meta: {
            isLoggedIn: false,
        },
    },

    {
        path: "/customer-forgot-password-send",
        name: "CustomerAuthForgotPasswordSend",
        component: () => import("../views/auth/PasswordSend.vue"),
        meta: {
            isLoggedIn: false,
        },
    },
    {
        path: "/customer-reset-password",
        name: "CustomerAuthResetPassword",
        component: () => import("../views/auth/ResetPassword.vue"),
        meta: {
            isLoggedIn: false,
        },
    },
    {
        path: "/customer-reset-password-success",
        name: "CustomerAuthResetPasswordSuccess",
        component: () => import("../views/auth/ResetPasswordSuccess.vue"),
        meta: {
            isLoggedIn: false,
        },
    },
    {
        path: "/customer-register",
        name: "CustomerAuthRegistration",
        component: () => import("../views/auth/Registration.vue"),
        meta: {
            isLoggedIn: false,
        },
    },
    {
        path: "/customer-registration-success",
        name: "CustomerAuthRegistrationSuccess",
        component: () => import("../views/auth/RegisterSuccess.vue"),
        meta: {
            isLoggedIn: false,
        },
    },
    {
        path: "/404",
        name: "404",
        component: () => import("../views/404/404Index.vue"),
        meta: {
            isLoggedIn: false,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach(async (to, from, next) => {
    // const isAdmin = true;
    const { user } = storeToRefs(useAuthStore());
    let isLoggedIn = getCookie("logged_in") ? true : null;
    if (isLoggedIn == null) {
        isLoggedIn = false;
    }
    // const routeRequiresAdmin = to.matched.some(
    //     (record) => record.meta.requiresAdmin
    // );
    const routeRequiresLogIn = to.matched.some(
        (record) => record.meta.isLoggedIn
    );
    //initilize with true for auth routes because there is no user_type set then and therefore the query down below wont be called
    let doUserTypesMatch = true;
    const required_user_types: string[] = to.meta
        .required_user_types as string[];
    if (required_user_types && localStorage.getItem("user_type")) {
        if (
            required_user_types.includes(
                localStorage.getItem("user_type") as string
            )
        ) {
            doUserTypesMatch = true;
        } else {
            doUserTypesMatch = false;
        }
    }
    if (!isLoggedIn && routeRequiresLogIn) {
        next({ name: "AuthLogin" });

        return;
    } else if (isLoggedIn && !routeRequiresLogIn) {
        next({ name: "NormAdministration" });
    } else if (!doUserTypesMatch) {
        if (user.value?.user_type == "user") {
            next({ name: "NormAdministration" });
        } else {
            next({ name: "CustomerNormAdministration" });
        }
    }
    next();
});
export default router;
