import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "close",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_1775",
      "data-name": "Rechteck 1775",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "close-2",
      "data-name": "close",
      d: "M656.239,93.283a1,1,0,0,0-.707.292l-4.282,4.282-4.282-4.282a1,1,0,0,0-1.414,0l-.011.012a1,1,0,0,0,0,1.414l4.282,4.282-4.282,4.281a1,1,0,0,0,0,1.415l.011.011a1,1,0,0,0,1.414,0l4.282-4.282,4.282,4.282a1,1,0,0,0,1.414,0l.011-.012a1,1,0,0,0,0-1.414l-4.281-4.281L656.957,95a1,1,0,0,0,0-1.414l-.011-.012a1,1,0,0,0-.707-.292Z",
      transform: "translate(-639.25 -87.283)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}