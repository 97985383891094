import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex-shrink-0 mr-3 md:mr-4 md:hidden"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 2,
  class: "text-xl mr-2 md:mr-10"
}
const _hoisted_4 = {
  key: 3,
  class: "gap-2 mr-4 md:mr-10 hidden md:flex"
}
const _hoisted_5 = { class: "flex mt-1.5 gap-1 sm:gap-2 md:gap-8 ml-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_arrow_left = _resolveComponent("icon-arrow-left")!
  const _component_icon_container = _resolveComponent("icon-container")!
  const _component_icon_arrow_right = _resolveComponent("icon-arrow-right")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-center w-full flex-shrink-0 h-14 z-40", `bg-${_ctx.finalNavBarStyle.backgroundColor} ${_ctx.finalNavBarStyle.paddingClasses} 
        ${_ctx.finalNavBarStyle.shadow}`])
  }, [
    (_ctx.$slots.sidebarToggle != null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "sidebarToggle")
        ]))
      : _createCommentVNode("", true),
    (_ctx.logoSource != null && _ctx.logoSource != '')
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "mr-2 md:mr-10 w-28 flex-shrink",
          src: _ctx.logoSource,
          alt: "logo",
          id: "logo"
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.title), 1)),
    (_ctx.hasNavigationButtons)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_icon_container, {
            id: "navbar-back",
            class: _normalizeClass(_ctx.hasBack ? '' : 'cursor-default'),
            onClick: _ctx.back,
            containerStyle: _ctx.computedBackIconContainerStyle
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_arrow_left, {
                class: _normalizeClass(_ctx.hasBack ? '' : 'opacity-50'),
                color: `${_ctx.finalNavBarStyle.navigationIconColor}`
              }, null, 8, ["class", "color"])
            ]),
            _: 1
          }, 8, ["class", "onClick", "containerStyle"]),
          _createVNode(_component_icon_container, {
            id: "navbar-forward",
            onClick: _ctx.forward,
            class: _normalizeClass(_ctx.hasForward ? '' : 'cursor-default'),
            containerStyle: _ctx.computedForwardIconContainerStyle
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_arrow_right, {
                class: _normalizeClass(_ctx.hasForward ? '' : 'opacity-50'),
                color: `${_ctx.finalNavBarStyle.navigationIconColor}`
              }, null, 8, ["class", "color"])
            ]),
            _: 1
          }, 8, ["onClick", "class", "containerStyle"])
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "center"),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "right")
    ])
  ], 2))
}