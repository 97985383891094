/**
 * Adds a cookie to the webbrowser storage cookies
 *
 * @param  {string} cookieName
 * @param  {boolean} isCookieActive
 * @param  {number} expiryDays - number of days (365 days for one year)
 *
 * @returns void
 */
export function setCookie(
    cookieName: string,
    isCookieActive: boolean,
    expiryDays: number
): void {
    const d = new Date();
    d.setTime(d.getTime() + expiryDays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie =
        cookieName + "=" + isCookieActive + ";" + expires + ";path=/";
}
/**
 * is returning a cookie name from the webbrowser storage cookies
 *
 * @param  {string} cookieName
 *
 * @returns string or nulöl
 */
export function getCookie(cookieName: string): null | string {
    const name = cookieName + "=";
    const allCookies = document.cookie.split(";");
    for (let i = 0; i < allCookies.length; i++) {
        let cookie = allCookies[i];

        while (cookie.charAt(0) == " ") {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) == 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return null;
}

/**
 * sets the expirydate to 1970 -> the browser removes this cookie because it is
 *
 * @param  {string} cookieName
 *
 * @returns void
 */
export function removeCookie(cookieName: string): void {
    if (getCookie(cookieName)) {
        document.cookie =
            cookieName + "=false;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
    }
}
